import cn from '@src/utils/class-names';
import React, { useState } from 'react';
import { fileEn, fileTh, TemplateType } from '.';
import CreditDialog from './credit-dialog';
import ContentsDialog from './content-dialog';
import { useCreditNumberByIdQuery, useUpdateEventNameByIdQuery } from '@src/services/queries/useEventsQuery';
import Swal from 'sweetalert2';
const btnStyle = `flex items-center justify-center gap-2 rounded shadow-md  bg-gradient-to-r border absolute top-24 right-0 m-4
w-full max-w-44  py-2 px-5 from-navy-500 to-navy-500
hover:to-navy-400 border-navy-600 text-white`;
type EditToolsBarProps = {
    setDataTemplate: (arg: any) => void;
    setFileTemplate: (arg: string) => void;
    fileTemplate: string;
    dataTemplate: TemplateType;
    eventId: string | number;
};
const EditToolsBar = ({ dataTemplate, fileTemplate, setFileTemplate, eventId }: EditToolsBarProps) => {
    const { mutateAsync } = useCreditNumberByIdQuery();

    const EventNameByIdQuery = useUpdateEventNameByIdQuery();
    const onHandleChangeTemplate = () => {
        if (fileTemplate !== fileEn) {
            setFileTemplate(fileEn);
        } else {
            setFileTemplate(fileTh);
        }
    };

    const [open, setOpen] = useState(false);
    const [openCredit, setOpenCredit] = useState(false);

    const handleClickOpenContent = () => {
        setOpen(true);
    };

    const handleClickOpenCredit = () => {
        setOpenCredit(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const onSubmitCredit = (data: { creditNumber: string }) => {
        if (data) {
            setOpenCredit(false);
            setOpen(false);
            mutateAsync(
                {
                    eventId,
                    creditNumber: data?.creditNumber,
                },
                {
                    onSuccess: data => {
                        Swal.fire({
                            icon: data.status === 200 ? 'success' : 'error',
                            title: data.status === 200 ? 'แก้ไขเลขที่เครดิตสำเร็จ!' : 'แก้ไขเลขที่เครดิตไม่สำเร็จ!',
                            confirmButtonText: 'ตกลง',
                            customClass: {
                                popup: 'popup-backend',
                            },
                        }).then(() => {
                            window.location.reload();
                        });
                    },
                },
            );
        }
    };

    const onSubmitContent = async (data: any) => {
        try {
            await EventNameByIdQuery.mutateAsync(
                {
                    eventId,
                    name: data?.name,
                },
                {
                    onSuccess: data => {
                        Swal.fire({
                            icon: data.status === 200 ? 'success' : 'error',
                            title: data.status === 200 ? 'แก้ไขข้อความสำเร็จ!' : 'แก้ไขข้อความไม่สำเร็จ!',
                            confirmButtonText: 'ตกลง',
                            customClass: {
                                popup: 'popup-backend',
                            },
                        }).then(() => {
                            window.location.reload();
                        });
                    },
                },
            );
        } catch (error) {}
    };

    return (
        <>
            <CreditDialog open={openCredit} handleClose={() => setOpenCredit(false)} defaultValue={dataTemplate.creditNumber} onSubmit={onSubmitCredit} />

            <ContentsDialog open={open} handleClose={handleClose} onSubmit={onSubmitContent} defaultValue={dataTemplate.title} />
            <button className={cn(btnStyle, 'top-0')} onClick={handleClickOpenCredit}>
                แก้ไขเลขที่เครดิต
            </button>
            <button className={cn(btnStyle, 'top-12')} onClick={handleClickOpenContent}>
                แก้ไขข้อความ
            </button>
            <button className={btnStyle} onClick={onHandleChangeTemplate}>
                เปลี่ยนภาษาเนื้อหา
            </button>
        </>
    );
};

export default EditToolsBar;
