import React, { useEffect, useState } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { green, yellow } from '@mui/material/colors';
import CircleIcon from '@mui/icons-material/Circle';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import './dashboard.css';
import Iconify from '@src/components/layout/admin/components/iconify';
import { useDeleteEventQuery, useEventsALLQuery, useUpdatePerEventQuery } from '@src/services/queries/useEventsQuery';
import { EventEmission } from '@src/types';
import { formatDateEn } from '@src/utils/format-date-th';
import Swal from 'sweetalert2';
import api from '@src/services/axios';
import { useGlobalState } from '@src/context/GlobalStateContext';
import { MiceForm } from '@src/types/mice-form.model';
import HiLightDashboard from '@src/components/Hi-LIght-Dashboard';
import { Box } from '@mui/material';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#CECECE',
        color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
export default function Dashboard() {
    const location = useLocation();
    const deleteEventMutation = useDeleteEventQuery();
    const updateEventMutation = useUpdatePerEventQuery();
    const { setFormMice, setGasEmissions, setProjectSelect } = useGlobalState();

    const navigate = useNavigate();
    const { t } = useTranslation();
    const [activeTabId, setActiveId] = useState<string>('all');
    const [openPostEvent, setOpenPostEvent] = useState<boolean>(false);
    const [eventId, setEventId] = useState<number>();
    const [events, setEvents] = useState<EventEmission[]>([]);
    const { data } = useEventsALLQuery();

    useEffect(() => {
        if (data) {
            const resultFilter = data?.data?.data?.sort((a: any, b: any) => {
                if (a.compensateStatus === 'WAIT_FOR_COMPENSATION' && b.compensateStatus !== 'WAIT_FOR_COMPENSATION') return -1;
                if (a.compensateStatus !== 'WAIT_FOR_COMPENSATION' && b.compensateStatus === 'WAIT_FOR_COMPENSATION') return 1;
                return 0;
            });
            setEvents(resultFilter);
        }
    }, [data]);

    function handleClickCreateProject() {
        sessionStorage.setItem('tmpCreateData', '');
        localStorage.removeItem('formMice');
        localStorage.removeItem('gasEmissions');
        sessionStorage.removeItem('tmpEventId');
        setFormMice(undefined as any);
        setGasEmissions(undefined as any);

        navigate('register/pre/1');
    }
    function handleClickSetting() {
        navigate('setting');
    }

    const handleClickTab = (keyTab: string) => {
        // const { id } = e.currentTarget;
        setActiveId(keyTab);
        const itemsEvent = data?.data?.data;
        if (keyTab === 'POST_EVENT') {
            const resultFilter = itemsEvent.filter((item: any) => item.isEvent === keyTab);
            setEvents(resultFilter);
        } else if (keyTab === 'PRE_EVENT') {
            const resultFilter = itemsEvent.filter((item: any) => item.isEvent === keyTab);
            setEvents(resultFilter);
        } else if (keyTab === 'COMPENSATED') {
            const resultFilter = itemsEvent.filter((item: any) => item.compensateStatus === keyTab);
            setEvents(resultFilter);
        } else {
            setEvents(itemsEvent);
        }
    };

    function handleClosePostEvent() {
        setOpenPostEvent(false);
    }

    function handleDeleteBtn(id: number) {
        Swal.fire({
            title: `${t('DASHBOARD_PAGE.MESSAGE_TITLE_EVENT')}`,
            text: `${t('DASHBOARD_PAGE.MESSAGE_DETAIL_EVENT')}`,
            icon: 'error',
            showCancelButton: true,
            confirmButtonColor: '#1AA238',
            cancelButtonColor: '#d33',
            cancelButtonText: `${t('BUTTONS.CANCEL')}`,
            confirmButtonText: `${t('BUTTONS.COMFIRM')}`,
            customClass: {
                popup: 'popup-backend',
            },
        }).then((result: any) => {
            if (result.isConfirmed) {
                deleteEventMutation.mutateAsync(id as number);
            }
        });
    }

    async function handleClickPreEvent() {
        const response = await api.get(`/events/${eventId}`);
        if (response.status === 200 && response.data) {
            setFormMice(new MiceForm(response?.data));
            setGasEmissions(response?.data?.emission?.emissions_sources);
            sessionStorage.setItem('tmpEventId', JSON.stringify(response?.data.id));

            let newCreateData = {
                status: 'pre-update',
                paging: 1,
            };
            sessionStorage.setItem('tmpCreateData', JSON.stringify(newCreateData));
            navigate(`register/pre-update/1`);
        }

        // state old logic nong non
    }

    async function handleClickPostEvent() {
        const response = await api.get(`/events/${eventId}`);
        if (response.status === 200 && response.data) {
            updateEventMutation.mutateAsync({
                eventId: response.data.id,
                preEvent: {
                    isEvent: 'POST_EVENT',
                },
            });

            setProjectSelect({
                id: response.data.projectId,
            });
            setFormMice(new MiceForm(response?.data));
            setGasEmissions(response?.data?.emission?.emissions_sources);
            sessionStorage.setItem('tmpEventId', JSON.stringify(response?.data.id));
            let tmpCreateData = {
                status: 'post',
                paging: 2,
            };
            sessionStorage.setItem('tmpCreateData', JSON.stringify(tmpCreateData));
            navigate('register/post/2');
        }
    }

    const handleViewSummaryEvent = async (id: number) => {
        navigate(`/view-summary/${id}`);
    };

    useEffect(() => {
        if (location.pathname && location.pathname === '/') {
            localStorage.removeItem('formMice');
            localStorage.removeItem('gasEmissions');
            localStorage.removeItem('projectSelect');
            sessionStorage.clear();
        }
    }, [location]);

    const handleClickEvent = (id: number) => {
        setEventId(id);
        setOpenPostEvent(true);
    };

    return (
        <div className="container ">
            <Box mt={4}>
                <HiLightDashboard handleCreateProject={() => handleClickCreateProject()} showSetting handleClickSetting={() => handleClickSetting()} />

                <div className="columns is-mobile">
                    <div className="column is-3 is-offset-1">
                        <h1 className="content-header content-left">{t('DASHBOARD_PAGE.PROJECT_TABLE.HEADER')}</h1>
                        <h3 className="content-sub-header content-left">{t('DASHBOARD_PAGE.PROJECT_TABLE.SUBHEADER')}</h3>
                    </div>
                </div>
                <div className="columns is-mobile">
                    <div className="column is-10 is-offset-1 box">
                        <div className="tabs is-fullwidth">
                            <ul>
                                <li className={activeTabId === 'all' ? 'is-active ' : ''} onClick={() => handleClickTab('all')}>
                                    {/* eslint-disable jsx-a11y/anchor-is-valid  */}
                                    <a className="cursor-pointer">
                                        <span>{t('DASHBOARD_PAGE.TABS.TAB1')}</span>
                                    </a>
                                </li>
                                <li className={activeTabId === 'PRE_EVENT' ? 'is-active' : ''} onClick={() => handleClickTab('PRE_EVENT')}>
                                    <a className="cursor-pointer">
                                        <span>{t('DASHBOARD_PAGE.TABS.TAB2')}</span>
                                    </a>
                                </li>
                                <li className={activeTabId === 'POST_EVENT' ? 'is-active' : ''} onClick={() => handleClickTab('POST_EVENT')}>
                                    <a className="cursor-pointer">
                                        <span>{t('DASHBOARD_PAGE.TABS.TAB3')}</span>
                                    </a>
                                </li>
                                <li className={activeTabId === 'COMPENSATED' ? 'is-active' : ''} onClick={() => handleClickTab('COMPENSATED')}>
                                    <a className="cursor-pointer">
                                        <span>{t('DASHBOARD_PAGE.TABS.TAB4')}</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell className="content-left" sx={{ width: '5%' }}>
                                            #
                                        </StyledTableCell>
                                        <StyledTableCell className="content-left" sx={{ width: '40%' }}>
                                            {t('DASHBOARD_PAGE.PROJECT_TABLE.PROJECT_NAME')}
                                        </StyledTableCell>
                                        <StyledTableCell className="content" sx={{ width: '20%' }}>
                                            {t('DASHBOARD_PAGE.PROJECT_TABLE.PROJECT_DATE')}
                                        </StyledTableCell>

                                        <StyledTableCell align="right" className="content-center pl-5" sx={{ width: '20%' }}>
                                            {t('DASHBOARD_PAGE.PROJECT_TABLE.PROJECT_STATUS')}
                                        </StyledTableCell>
                                        <StyledTableCell align="left" className="content-center" sx={{ width: '10%' }}>
                                            {t('DASHBOARD_PAGE.PROJECT_TABLE.PROJECT_ACTION')}
                                        </StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {events.map((row: EventEmission, index) => (
                                        <StyledTableRow key={row.id}>
                                            <StyledTableCell align="left">{index + 1}</StyledTableCell>
                                            <StyledTableCell component="th" scope="row" className="content-left">
                                                <div>
                                                    <p className="content-left">
                                                        <b>{row.name}</b>
                                                    </p>
                                                    <p className="content-left">{row?.location}</p>
                                                </div>
                                            </StyledTableCell>
                                            <StyledTableCell align="left" className="content">
                                                {formatDateEn(row.createdAt)}
                                            </StyledTableCell>
                                            <StyledTableCell align="left" className="content">
                                                {/* {row.compensateStatus} <br />
                                            {row.paymentStatus} */}
                                                <div className="flex justify-end max-w-60 items-center">
                                                    <button
                                                        onClick={() =>
                                                            row.compensateStatus !== 'COMPENSATED' && row.paymentStatus !== 'COMPLETE'
                                                                ? handleClickEvent(row.id as number)
                                                                : row.compensateStatus === 'COMPENSATED' && row.paymentStatus === 'COMPLETE'
                                                                  ? handleViewSummaryEvent(row.id as number)
                                                                  : ''
                                                        }
                                                        className="font-Kanit button shadow-lg is-rounded is-small is-success font-semibold
                          hover:shadow-lg transition-shadow duration-150  "
                                                        style={
                                                            row.compensateStatus === 'WAIT_FOR_COMPENSATION'
                                                                ? {
                                                                      backgroundColor: '#ffb700',
                                                                      color: 'black',
                                                                  }
                                                                : row.compensateStatus === 'ON_HOLD'
                                                                  ? { backgroundColor: '#ffb700' }
                                                                  : row.compensateStatus === 'COMPENSATED'
                                                                    ? { borderColor: '#00b300', color: 'white' }
                                                                    : {}
                                                        }
                                                    >
                                                        {row.compensateStatus === 'WAIT_FOR_COMPENSATION' && row.paymentStatus === 'ON_HOLD'
                                                            ? `${row.isEvent === 'PRE_EVENT' ? t('DASHBOARD_PAGE.PROJECT_ACTION.PRE') : t('DASHBOARD_PAGE.PROJECT_ACTION.POST')}`
                                                            : ''}

                                                        {row.compensateStatus === 'WAIT_FOR_COMPENSATION' && row.paymentStatus === 'COMPLETE'
                                                            ? t('DASHBOARD_PAGE.PROJECT_ACTION.WAIT_FOR_COMPENSATION')
                                                            : ''}

                                                        {row.compensateStatus === 'COMPENSATED' ? t('DASHBOARD_PAGE.PROJECT_ACTION.SUCCESS') : ''}
                                                    </button>
                                                    <CircleIcon
                                                        className="blink"
                                                        sx={{
                                                            color: row.isEvent === 'PRE_EVENT' ? yellow[700] : green[400],
                                                            marginLeft: '10px',
                                                        }}
                                                    />
                                                </div>
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                {row.isEvent === 'PRE_EVENT' && (
                                                    <button className=" is-small" onClick={() => handleDeleteBtn(row.id as number)} type="button">
                                                        <Iconify icon="eva:trash-2-outline" sx={{ color: 'red' }} />
                                                    </button>
                                                )}
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            </Box>
            <Dialog
                open={openPostEvent}
                onClose={handleClosePostEvent}
                fullWidth
                maxWidth="xs"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                PaperProps={{
                    style: {
                        borderRadius: '15px', // Adjust the value as needed
                    },
                }}
            >
                <DialogContent dividers={false}>
                    <div className="column is-12">
                        <h4 className="content-center">{t('DASHBOARD_PAGE.POST_EVENT_DIALOG.CONTENT')}</h4>
                    </div>
                </DialogContent>
                <DialogActions>
                    <div className="column">
                        <div className="buttons" style={{ justifyContent: 'center' }}>
                            <button className="button App bg-[#FEC236]" type="button" onClick={handleClickPreEvent}>
                                {t('DASHBOARD_PAGE.POST_EVENT_DIALOG.PRE_EVENT')}
                            </button>
                            <button className="button is-success App" type="submit" onClick={handleClickPostEvent}>
                                {t('DASHBOARD_PAGE.POST_EVENT_DIALOG.POST_EVENT')}
                            </button>
                        </div>
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    );
}
