import { useQuery } from '@tanstack/react-query';
import api from '../axios';

export const useProjectsQuery = () => {
    return useQuery({
        queryKey: ['projects'],
        queryFn: async () => {
            try {
                const { data, status } = await api.get('/projects');
                return {
                    data,
                    status,
                };
            } catch (error: any) {
                return {
                    data: error?.response?.data,
                    status: error?.response?.status,
                };
            }
        },
    });
};
