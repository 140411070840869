import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './payment.css';
import { useProjectsQuery } from '@src/services/queries/useProjectQuery';
import { EmissionsSource, Project } from '@src/types';
import { IProject, useGlobalState } from '@src/context/GlobalStateContext';
import { useUpdatePerEventQuery } from '@src/services/queries/useEventsQuery';
import { formatNumber } from '@src/utils/format-number';
import IconBack from './Element/IconBack';
import Swal from 'sweetalert2';

export default function Offset({ eventId }: { eventId: number }) {
    const { projectSelect, setProjectSelect, gasEmissions } = useGlobalState();
    const updatePerEventQuery = useUpdatePerEventQuery();
    const { t } = useTranslation();
    const projectsQuery = useProjectsQuery();

    const [projects, setProjects] = useState<Project[]>([]);
    const [selectedValue, setSelectedValue] = useState<string | null>('');

    const handleChange = (e: React.MouseEvent<HTMLElement>, nextView: string) => {
        const resultFilter = projects?.find(p => p.id === parseInt(nextView));
        setProjectSelect({
            id: resultFilter?.id.toString(),
            price: resultFilter?.price,
            quantity: resultFilter?.quantity,
        } as IProject);
        setSelectedValue(nextView);
    };
    const { totalCarbonFootprint } = useMemo(() => {
        return (gasEmissions || []).reduce(
            (totals: any, source: EmissionsSource) => {
                totals.totalCarbonFootprint += source.percentage;
                return totals;
            },
            { totalCarbonFootprint: 0 },
        );
    }, [gasEmissions]);

    const totalCarbonFootprint2 = Math.ceil(totalCarbonFootprint);

    useEffect(() => {
        if (projectsQuery?.data) {
            const { data } = projectsQuery?.data;
            const newProjects = data.data.filter((p: any) => p.quantity >= totalCarbonFootprint2);
            setProjects(newProjects);
            if (newProjects.length === 0) {
                setSelectedValue('');
                setProjectSelect(undefined as any);
            }
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectsQuery?.data]);

    const autoSetProject = useCallback(() => {
        if (projects?.length > 0) {
            const projectFind = projects?.find(p => p.id === parseInt(selectedValue as string));
            setProjectSelect({
                id: projectFind?.id.toString(),
                price: projectFind?.price,
                quantity: projectFind?.quantity,
            } as IProject);
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projects]);
    useEffect(() => {
        if (projectSelect && projectSelect?.id) {
            setSelectedValue(projectSelect.id.toString());
            autoSetProject();
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autoSetProject]);

    const handleNext = async () => {
        const response = await updatePerEventQuery.mutateAsync({
            eventId: eventId,
            preEvent: {
                projectId: parseInt(projectSelect?.id as string),
            },
        });

        let tmpCreateData = {
            status: 'post',
            paging: 9,
        };
        sessionStorage.setItem('tmpCreateData', JSON.stringify(tmpCreateData));

        if (response.status === 200) {
            window.location.href = '/register/post/9';
        } else {
            Swal.fire({
                icon: 'error',
                title: 'เกิดข้อผิดพลาด!',
                text: 'ไม่สามารถดำเนินการต่อไปได้',
                confirmButtonText: 'ตกลง',
            });
        }
    };

    const handleBack = () => {
        let tmpCreateData = {
            status: 'post',
            paging: 7,
        };
        sessionStorage.setItem('tmpCreateData', JSON.stringify(tmpCreateData));
        window.location.href = '/register/post/7';
    };

    const handleCancel = () => {
        setSelectedValue('');
    };

    return (
        <div className="column is-6 is-offset-3">
            <div className="box" style={{ marginBlockStart: '80px', borderRadius: '90px' }}>
                <IconBack onClick={handleBack} />
                <div className="columns is-mobile">
                    <div className="column" style={{ marginBlockStart: '20px' }}>
                        <h1 className="content-header content-left" style={{ paddingLeft: '46px' }}>
                            {t('PAID_PAGE.HEADER')}
                        </h1>
                        <br />
                        <div className="max-w-sm mx-auto my-8">
                            <div className="border-[#C8F026]  border-[8px]  p-8 rounded-xl">
                                <div className="text-sky-500 text-5xl font-Kanit font-bold">
                                    {totalCarbonFootprint2}

                                    {t('SUMMARY_PAGE.UNIT')}
                                </div>
                            </div>
                        </div>
                        {selectedValue && (
                            <div className="flex justify-end p-4">
                                <div className="cursor-pointer underline text-lg" onClick={handleCancel}>
                                    {t('CANCEL_PROJECT')}
                                </div>
                            </div>
                        )}
                        {projects.length > 0 && (
                            <ToggleButtonGroup className="gap-y-2" color="info" exclusive value={selectedValue} orientation="vertical" onChange={handleChange}>
                                {projects?.map((project: Project) => (
                                    <ToggleButton
                                        key={project.id}
                                        value={project.id.toString()}
                                        aria-label="list"
                                        style={{
                                            borderColor: '#E2E2E2',
                                            width: '520px',
                                            borderRadius: '10px',
                                            display: 'block',
                                            textTransform: 'none',
                                        }}
                                    >
                                        <p className="content-left" style={{ fontSize: '20px' }}>
                                            {project.nameTH ?? ''}
                                        </p>
                                        <div className="content-right text-black" style={{ fontSize: '20px' }}>
                                            <div className="pr-2">
                                                {formatNumber(project.price)} / {project.quantity}
                                            </div>
                                            <div className="text-[17px] -mt-3"> {t('PAID_PAGE.CURRENCY_EXCHANGE_RATE')}</div>
                                        </div>
                                    </ToggleButton>
                                ))}
                            </ToggleButtonGroup>
                        )}

                        {projects.length === 0 && (
                            <div>
                                <p className="" style={{ fontSize: '20px' }}>
                                    {t('NO_PROJECT')}
                                </p>
                            </div>
                        )}
                    </div>
                </div>
                <div className="columns">
                    <div className="column is-6 is-offset-3" style={{ alignItems: 'right', marginTop: '10px' }}>
                        <button
                            onClick={handleNext}
                            className="button is-large is-fullwidth App"
                            style={{ backgroundColor: '#C8F026', fontSize: '18px', height: '70px', borderRadius: '15px' }}
                            disabled={selectedValue === '' || projects.length === 0}
                        >
                            {t('BUTTONS.NEXT')}
                        </button>
                    </div>
                </div>
                <br />
            </div>
        </div>
    );
}
