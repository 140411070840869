import { Button, Grid, IconButton, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { LocationFormInput, LocationFormSchema } from '@src/validators/locationFormSchema';
import { useTranslation } from 'react-i18next';
import { Profile } from '@src/utils/state-profile';
import { useUpdateLocationUser, useUserById } from '@src/services/queries/useUsersQuery';
import Swal from 'sweetalert2';
import { formatNumber } from '@src/utils/format-number';
import { useQueryClient } from '@tanstack/react-query';
import Iconify from '@src/components/layout/admin/components/iconify';
import { useNavigate } from 'react-router';

export const SettingLocation = () => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const { t } = useTranslation();
    const [location, setLocation] = useState<any[]>([]);
    const [editIndex, setEditIndex] = useState<number>();
    const [isEdit, setIsEdit] = useState<boolean>();

    const profile = Profile();
    const { data } = useUserById(profile.mapId);

    const updateLocationUserQuery = useUpdateLocationUser();
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm<LocationFormInput>({
        resolver: zodResolver(LocationFormSchema),
    });

    const onSubmit = async (formData: LocationFormInput) => {
        //@ts-ignore
        formData.area = parseInt(formData.area);
        if (isEdit) {
            onUpdateLocation(formData);
        } else {
            const checkName = location.find(item => item.name === formData.name);
            if (!checkName) {
                setLocation(prevState => [...prevState, formData]);
                const state = {
                    savedEventLocation: [...location, formData],
                };
                const response = await updateLocationUserQuery.mutateAsync({
                    userId: data.id,
                    user: state,
                });
                if (response.status === 200) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: 'Save location success',
                        timer: 1500,
                        showConfirmButton: false,
                    }).then(async () => {
                        await queryClient.invalidateQueries({ queryKey: ['userId', data.id] });
                        reset({
                            name: '',
                            //@ts-ignore
                            area: null,
                        });
                    });
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: t('DUPLICATE_NAME'),
                    text: t('DUPLICATE_NAME_DETAIL'),
                });
            }
        }
    };
    const onUpdateLocation = async (values: any) => {
        const response = await updateLocationUserQuery.mutateAsync({
            userId: data.id,
            user: {
                savedEventLocation: location.map((item, i) => {
                    if (i === editIndex) {
                        return values;
                    }
                    return item;
                }),
            },
        });
        if (response.status === 200) {
            Swal.fire({
                icon: 'success',
                title: 'Updated',
                text: 'Update location success',
                timer: 1500,
                showConfirmButton: false,
            }).then(async () => {
                await queryClient.invalidateQueries({ queryKey: ['userId', data.id] });
                onCancel();
            });
        }
    };
    useEffect(() => {
        if (data && data.savedEventLocation) {
            setLocation(data.savedEventLocation ?? []);
        }
    }, [data]);

    const removeLocation = async (index: number) => {
        const newState = location.filter((item, i) => i !== index);
        const response = await updateLocationUserQuery.mutateAsync({
            userId: data.id,
            user: {
                savedEventLocation: newState,
            },
        });
        if (response.status === 200) {
            Swal.fire({
                icon: 'success',
                title: 'Deleted',
                text: 'Delete location success',
                timer: 1500,
                showConfirmButton: false,
            }).then(async () => {
                await queryClient.invalidateQueries({ queryKey: ['userId', data.id] });
            });
        }
    };

    const editLocation = (index: number) => {
        reset({
            ...location[index],
        });
        setIsEdit(true);
        setEditIndex(index);
    };
    const onCancel = () => {
        setIsEdit(false);
        setEditIndex(-1);
        reset({
            name: '',
            //@ts-ignore
            area: null,
        });
    };

    const handleBack = () => {
        try {
            navigate(-1);
        } catch (error) {
            navigate('/');
        }
    };

    return (
        <div className="container text-left">
            <div className=" mt-6">
                <div className="my-4">
                    <Button variant="outlined" className="!font-Kanit !rounded-lg" color="inherit" size="small" onClick={handleBack}>
                        <IconButton>
                            <Iconify icon="bitcoin-icons:arrow-left-filled" sx={{ fontSize: 18, color: '#000' }} width={20} />
                        </IconButton>
                        {t('BUTTONS.BACKWARD')}
                    </Button>
                </div>
                <h2 className="text-4xl">{t('MANAGE_PLACES')}</h2>
            </div>

            <div className="flex flex-col max-w-3xl">
                <div className="px-4">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <label> {t('FORM_PAGE.SELECT_PLACE')}</label>
                                <TextField
                                    {...register('name')}
                                    error={!!errors.name}
                                    helperText={errors.name?.message}
                                    fullWidth
                                    size="small"
                                    InputProps={{
                                        sx: { borderRadius: 1.6, backgroundColor: 'white' },
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} sm={3}>
                                <label> {t('FORM_PAGE.SQM')}</label>
                                <TextField
                                    {...register('area')}
                                    error={!!errors.area}
                                    helperText={errors.area?.message}
                                    fullWidth
                                    size="small"
                                    InputProps={{
                                        sx: { borderRadius: 1.6, backgroundColor: 'white' },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    className="!font-Kanit "
                                    sx={{
                                        mt: 3,
                                        backgroundColor: isEdit ? '#D2FF72' : '#48c78e',
                                        color: isEdit ? '#000' : '#fff',
                                        ':hover': {
                                            backgroundColor: isEdit ? '#D2FF72' : '#3ad398',
                                        },
                                        borderRadius: 1.6,
                                    }}
                                >
                                    {t(isEdit ? 'BUTTONS.UPDATE_SAVE' : 'BUTTONS.SAVE')}
                                </Button>
                                {isEdit && (
                                    <Button
                                        onClick={onCancel}
                                        type="submit"
                                        variant="contained"
                                        className="!font-Kanit "
                                        sx={{
                                            mt: 3,
                                            backgroundColor: '#C7253E',
                                            color: '#fff',
                                            ':hover': {
                                                backgroundColor: '#C7253E',
                                            },
                                            borderRadius: 1.6,
                                        }}
                                    >
                                        {t('BUTTONS.CANCEL')}
                                    </Button>
                                )}
                            </Grid>
                        </Grid>
                    </form>
                </div>
                <table className="min-w-full text-left   text-surface  mt-4">
                    <thead className="border-b border-neutral-200 font-medium ">
                        <tr>
                            <th scope="col" className="px-6 py-4">
                                {t('FORM_PAGE.SELECT_PLACE')}
                            </th>
                            <th scope="col" className="px-6 py-4">
                                {t('FORM_PAGE.SQM')}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {location.map((item, index) => (
                            <tr key={index} className="border-b border-neutral-200 ">
                                <td className="px-6 py-4">{item.name}</td>
                                <td className="px-6 py-4 text-red-500">{formatNumber(item.area)}</td>
                                <td className="px-6 py-4 text-red-500">
                                    <IconButton onClick={() => editLocation(index)}>
                                        <Iconify icon="ph:pen" />
                                    </IconButton>
                                    <IconButton onClick={() => removeLocation(index)}>
                                        <Iconify icon="ic:round-delete" />
                                    </IconButton>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};
