import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AuthRoutes, GuestRoutes } from './AppRoutes';
//@ts-ignore
import Cookies from 'js-cookie';
import { decodeJwt } from '@src/utils/decode-jwt';
import { config } from '@src/config';
import { getGuest } from '@src/utils/state-guest';
import App from '@src/App';
export const RouterApp = () => {
    const isTokenExpired = React.useMemo(() => {
        const token = Cookies.get(config.keyNameCookie);
        if (!token) return true; // If no token, consider it expired
        try {
            const decodedToken = decodeJwt(token); // Decode the token
            const expirationTime = decodedToken.exp * 1000;
            if (!expirationTime) return true;
            return Date.now() > expirationTime;
        } catch (error) {
            console.error('Error decoding token:', error);
            return true;
        }
    }, []);

    const isGuest = getGuest();
    return <BrowserRouter>{isGuest ? <GuestRoutes /> : isTokenExpired ? <AuthRoutes /> : <App />}</BrowserRouter>;
};
