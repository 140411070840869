import React, { useMemo, useState } from 'react';
import { emptyRows, applyFilter, getComparator } from '@src/components/layout/admin/components/TableZeroCarbon/utils';
import Scrollbar from '@src/components/scrollbar';
import { Button, Card, Checkbox, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TablePagination } from '@mui/material';
import TableHeadZero from '@src/components/layout/admin/components/TableZeroCarbon/TableHead';
import TableEmptyRows from '@src/components/layout/admin/components/TableZeroCarbon/TableEmptyRows';
import TableToolBar from '@src/components/layout/admin/components/TableZeroCarbon/TableToolBar';
import { formatDate } from '@src/utils/format-date-th';
import { useNavigate } from 'react-router';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import TableRowZeroCarbon from '../../../components/TableZeroCarbon/TableRow';
import Iconify from '../../../components/iconify';
import { useDeleteEventQuery, usePostEventsQuery, useUpdateEventQuery } from '@src/services/queries/useEventsQuery';
import { EventEmission } from '@src/types';
import TableLoading from '../../../components/TableZeroCarbon/TableLoading';
import CompensateStatusBadge from '../../../components/Element/CompensateStatusBadge';
import cn from '@src/utils/class-names';
import WidgetSummary from '../../../components/widget-summary';
import { formatNumber } from '@src/utils/format-number';
import { PaymentStatus } from '../../../common/paymentStatus';
import Swal from 'sweetalert2';
import { useQueryClient } from '@tanstack/react-query';
import api from '@src/services/axios';

interface PostEventTableProp {
    filterSelect: {
        projectType: string;
        years: string;
        quarter: string;
    };
}

const PostEventTable = ({ filterSelect }: PostEventTableProp) => {
    const queryClient = useQueryClient();
    const updateEventQuery = useUpdateEventQuery();
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState<any>([]);
    const [orderBy, setOrderBy] = useState('#');
    const filterName = '';
    const [search, setSearch] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(20);

    const navigate = useNavigate();

    const { data, isLoading } = usePostEventsQuery(filterSelect.projectType, filterSelect.years, filterSelect.quarter, search, page + 1, rowsPerPage);

    const deleteEventMutation = useDeleteEventQuery();

    const widgetSummaryProps = [
        {
            title: 'รอชำระเงิน',
            value: data?.eventStats?.unpaidCount ?? 0,
            type: 'กิจกรรม',
            detail: `${formatNumber(data?.eventStats?.unpaidBaht ?? 0)} บาท`,
        },
        {
            title: 'ชำระเงินแล้ว',
            value: data?.eventStats?.paidCount ?? 0,
            type: 'กิจกรรม',
            detail: `${formatNumber(data?.eventStats?.paidBaht ?? 0)} บาท`,
        },
        {
            title: 'รอชดเชย GHG',
            value: data?.eventStats?.uncompensatedCount ?? 0,
            type: 'กิจกรรม',
            detail: `${formatNumber(data?.eventStats?.uncompensatedTon ?? 0)} ตัน`,
        },
        {
            title: 'ชดเชย GHG แล้ว',
            value: data?.eventStats?.compensatedCount ?? 0,
            type: 'กิจกรรม',
            detail: `${formatNumber(data?.eventStats?.compensatedTon ?? 0)} ตัน`,
        },
    ];
    const handleSort = (event: any, id: any) => {
        const isAsc = orderBy === id && order === 'asc';
        if (id !== '') {
            setOrder(isAsc ? 'desc' : 'asc');
            setOrderBy(id);
        }
    };

    const handleClick = (event: React.MouseEvent<unknown>, name: object) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected: any = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleFilter = (query: string) => {
        setPage(0);
        setSearch(query);
    };

    const rowPostEvents = data?.data || [];
    const newRowPostEvents = rowPostEvents.map((event: any) => {
        event.subCategoryName = event?.subCategory?.name || '';
        event.summary = event?.emission?.summary || 0;
        event.summary = event?.emission?.summary || 0;

        return event;
    });
    const postEvents = newRowPostEvents || [];
    const dataFiltered = applyFilter({
        inputData: postEvents,
        comparator: getComparator(order, orderBy),
        filterName,
    });

    const handleView = (id: number | undefined) => {
        navigate(`/admin/post-event/${id}`);
    };

    const handleDeletePostEvent = async (id: number) => {
        Swal.fire({
            title: 'ต้องการลบ Event นี้?',
            text: 'คุณต้องการลบ Event นี้หรือไม่?',
            icon: 'error',
            showCancelButton: true,
            confirmButtonColor: '#1AA238',
            cancelButtonColor: '#d33',
            cancelButtonText: 'ยกเลิก',
            confirmButtonText: 'ยืนยัน',
            customClass: {
                popup: 'popup-backend',
            },
        }).then(async (result: any) => {
            if (result.isConfirmed) {
                const result = await deleteEventMutation.mutateAsync(id as number);
                if (result.status === 200) {
                    Swal.fire({
                        title: 'ลบ Event สำเร็จ',
                        icon: 'success',
                        timer: 1500,
                        showCancelButton: false,
                        confirmButtonColor: '#1AA238',
                        customClass: {
                            popup: 'popup-backend',
                        },
                    }).then(() => {
                        window.location.reload();
                    });
                } else {
                    Swal.fire({
                        title: 'เกิดข้อผิดพลาด !',
                        text: 'ไม่สามารถลบ Event สำเร็จ',
                        icon: 'error',
                        confirmButtonColor: '#d33',
                        confirmButtonText: 'ปิด',
                        customClass: {
                            popup: 'popup-backend',
                        },
                    });
                }
            }
        });
    };

    const TableRowBody = ({
        index,
        rows,
        handleClick,
        selected,
    }: {
        index: number;
        rows: EventEmission;
        handleClick: (arg: any) => void;
        selected: boolean | undefined;
    }) => {
        const { id, name, location, startDate, endDate, emission, paymentStatus, compensateStatus, subCategory, eventMember } = rows;
        return (
            <>
                <TableCell padding="checkbox">
                    <Checkbox
                        sx={{
                            width: 12,
                            p: 0,
                            // display: compensateStatus === 'WAIT_FOR_COMPENSATION' && paymentStatus === 'COMPLETE' ? 'inlineBlock' : 'none',
                        }}
                        checked={selected}
                        onChange={handleClick}
                    />
                </TableCell>
                <TableCell
                    sx={{
                        textAlign: 'center',
                    }}
                >
                    {index + 1}
                </TableCell>

                <TableCell scope="row">
                    <div className="truncate max-w-72 flex flex-col" title={name}>
                        {name}
                        <span className="text-black/80 font-light">{location}</span>
                    </div>
                </TableCell>
                <TableCell sx={{ textAlign: 'center' }}>
                    <div className="text-center">{subCategory?.name}</div>
                </TableCell>
                <TableCell> {eventMember ? eventMember : 0} </TableCell>
                <TableCell>{emission?.summary ? emission?.summary.toFixed(2) : 0}</TableCell>

                <TableCell>
                    {' '}
                    {formatDate(startDate)}
                    {' - '}
                    {formatDate(endDate)}
                </TableCell>
                <TableCell>
                    <span
                        className={cn(
                            'text-emerald-400 font-semibold',
                            paymentStatus === 'COMPLETE'
                                ? 'text-emerald-500'
                                : paymentStatus === 'ON_HOLD'
                                  ? 'text-orange-500'
                                  : paymentStatus === 'CANCEL'
                                    ? 'text-black'
                                    : paymentStatus === 'FAIL'
                                      ? 'text-red-500'
                                      : paymentStatus === 'INCOMPLETE'
                                        ? 'text-cyan-500'
                                        : 'text-gray-700',
                        )}
                    >
                        {PaymentStatus(paymentStatus)}
                    </span>
                </TableCell>
                <TableCell align="center">
                    <div className="flex justify-center">
                        <CompensateStatusBadge compensateStatus={compensateStatus} />
                    </div>
                </TableCell>

                <TableCell align="right">
                    <IconButton aria-label="view" size="small">
                        <VisibilityOutlinedIcon onClick={() => handleView(id)} />
                    </IconButton>
                    <IconButton aria-label="delete" size="small">
                        <DeleteOutlinedIcon onClick={() => handleDeletePostEvent(id as number)} />
                    </IconButton>
                </TableCell>
            </>
        );
    };

    const TotalCarbon = useMemo(() => {
        const filteredPostEvents = dataFiltered.filter(event => selected.includes(event.id));
        const totalSummary = filteredPostEvents.reduce((acc, item) => acc + item?.emission?.summary ?? 0, 0);
        return totalSummary ?? 0;
    }, [selected, dataFiltered]);

    const compensateStatusFilter = useMemo(() => {
        const findCompensateStatus = dataFiltered.find(event => selected.includes(event.id));
        return findCompensateStatus && findCompensateStatus.compensateStatus === 'COMPENSATED' ? true : false;
    }, [selected, dataFiltered]);

    const onCompensated = () => {
        Swal.fire({
            title: 'ต้องการ ชดเชย GHG หรือไม่?',
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#1AA238',
            cancelButtonColor: '#d33',
            cancelButtonText: 'ยกเลิก',
            confirmButtonText: 'ยืนยัน',
            customClass: {
                popup: 'popup-backend',
            },
        }).then((result: any) => {
            if (result.isConfirmed) {
                onUpdateCompensateStatus();
            }
        });
    };

    const onUpdateCompensateStatus = async () => {
        const postEvents = dataFiltered.filter(event => selected.includes(event.id)).map(e => e.id);
        let countSuccess = 0;
        postEvents.forEach(async eventId => {
            const response = await updateEventQuery.mutateAsync({
                eventId: eventId,
                eventData: {
                    compensateStatus: 'COMPENSATED',
                },
            });
            if (response.status === 200) {
                countSuccess += 1;
            }
            if (selected.length === countSuccess) {
                Swal.fire({
                    title: 'ชดเชย GHG เรียบร้อยแล้ว',
                    icon: 'success',
                    showCancelButton: false,
                    timer: 1500,
                    customClass: {
                        popup: 'popup-backend',
                    },
                }).then(() => {
                    setSelected([]);
                    queryClient.invalidateQueries({
                        queryKey: ['postEvents', filterSelect.projectType, filterSelect.years, filterSelect.quarter, search, page + 1, rowsPerPage],
                    });
                });
            }
        });
    };

    const handleExport = async () => {
        try {
            const response = await api.post(
                `/events/post-event/export`,
                { post_event_ids: selected },
                {
                    headers: { 'Content-Type': 'application/json' },
                    responseType: 'blob',
                },
            );
            if (response) {
                var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' });
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'ZeroCarbon_' + Date.now() + '.xlsx';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                setSelected([]);
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'เกิดข้อผิดพลาด ไม่สามารถ Export ได้',
            });
        }
    };

    return (
        <>
            <Grid container spacing={3} mb={2}>
                {widgetSummaryProps.map((item, index) => (
                    <Grid key={index} xs={12} sm={3} md={3} item>
                        <WidgetSummary {...item} />
                    </Grid>
                ))}
            </Grid>
            <TableToolBar
                totalCarbon={TotalCarbon}
                numSelected={selected}
                onCompensated={onCompensated}
                compensateStatusFilter={compensateStatusFilter}
                ButtonExport={
                    <Button
                        variant="contained"
                        sx={{
                            color: 'black',
                            borderLeftRadius: '10px',

                            backgroundColor: '#8edd72',
                            '&:hover': {
                                backgroundColor: '#69b54c',
                            },
                        }}
                        onClick={handleExport}
                    >
                        <Iconify icon="vscode-icons:file-type-excel2" /> Export
                    </Button>
                }
                onSearch={handleFilter}
            />
            <Scrollbar>
                <Card>
                    <TableContainer component={Paper}>
                        <Table
                            sx={{
                                minWidth: {
                                    sm: 1700,
                                    md: 1600,
                                    lg: 1300,
                                },
                            }}
                            size={'small'}
                        >
                            <colgroup>
                                <col width="1%" />
                                <col width="1%" />
                                <col width="15%" />
                                <col width="15%" />
                                <col width="10%" />
                                <col width="14%" />
                                <col width="12%" />
                                <col width="10%" />
                                <col width="5%" />
                                <col width="7%" />
                            </colgroup>
                            <TableHeadZero
                                defaultChecked={true}
                                order={order}
                                orderBy={orderBy}
                                rowCount={postEvents.length}
                                numSelected={selected.length}
                                onRequestSort={handleSort}
                                headLabel={[
                                    { id: '', label: 'ลำดับ', align: 'center' },
                                    { id: 'name', label: 'ชื่อกิจกรรม' },
                                    { id: 'subCategoryName', label: 'ประเภทกิจกรรม', align: 'center' },
                                    { id: 'eventMember', label: 'จำนวนผู้ร่วม (คน/วัน)' },
                                    { id: 'summary', label: 'ปริมาณคาร์บอนฟุตพริ้นท์รวม (ตัน)	' },
                                    { id: 'startDate', label: 'วันที่จัดกรรม', align: 'center' },
                                    { id: 'paymentStatus', label: 'สถานะการชำระเงิน' },
                                    { id: 'compensateStatus', label: 'สถานะชดเชย' },
                                    { id: '', label: 'เครื่องมือ' },
                                ]}
                            />
                            <TableBody sx={{ fontSize: '0.75rem' }}>
                                {isLoading && <TableLoading />}

                                {dataFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                                    <TableRowZeroCarbon
                                        selected={selected}
                                        rows={row}
                                        index={index}
                                        key={index}
                                        defaultChecked={true}
                                        handleClick={(event: any) => handleClick(event, row.id)}
                                        TableRowBody={
                                            <TableRowBody
                                                key={row.id}
                                                index={index}
                                                selected={selected.indexOf(row.id) !== -1}
                                                rows={row}
                                                handleClick={(event: any) => handleClick(event, row.id)}
                                            />
                                        }
                                    />
                                ))}

                                <TableEmptyRows height={77} emptyRows={emptyRows(page, rowsPerPage, postEvents.length)} />
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Card>
            </Scrollbar>

            <TablePagination
                count={data?.total || 0}
                onPageChange={handleChangePage}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[10, 25]}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    );
};

export default PostEventTable;
