import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import api from '../axios';

export const useEventsALLQuery = () => {
    return useQuery({
        queryKey: ['events'],
        queryFn: async () => {
            try {
                const { data, status } = await api.get(`/events`);
                return { data: { ...data }, status };
            } catch (error) {
                throw error;
            }
        },
    });
};

export const usePreEventsQuery = (subCategoryId: string, year: string, quarter: string, search: string, page: number, limit: number) => {
    return useQuery({
        queryKey: ['events', subCategoryId, year, quarter, search, page, limit],
        queryFn: async ({ queryKey }: { queryKey: [string, string, string, string, string, number, number] }) => {
            const [, subCategoryId, year, quarter, search, , limit] = queryKey;
            try {
                const { data } = await api.get(
                    `/events/pre-event?subCategoryId=${subCategoryId}&year=${year}&quarter=${quarter}&search=${search}&limit=${limit}`,
                );

                return data;
            } catch (error) {
                throw error;
            }
        },
    });
};

export const usePostEventsQuery = (subCategoryId: string, year: string, quarter: string, search: string, page: number, limit: number) => {
    return useQuery({
        queryKey: ['postEvents', subCategoryId, year, quarter, search, page, limit],
        queryFn: async ({ queryKey }: { queryKey: [string, string, string, string, string, number, number] }) => {
            const [, subCategoryId, year, quarter, search, , limit] = queryKey;
            try {
                const { data } = await api.get(
                    `/events/post-event?subCategoryId=${subCategoryId}&year=${year}&quarter=${quarter}&search=${search}&limit=${limit}`,
                );
                return data;
            } catch (error) {
                throw error;
            }
        },
    });
};
export const useEventsByIdQuery = (id: number | string) => {
    return useQuery({
        queryKey: ['eventsId', id],
        queryFn: async ({ queryKey }: { queryKey: [string, number | string] }) => {
            const [, id] = queryKey;
            try {
                const { data } = await api.get(`/events/${id}`);
                return data;
            } catch (error) {
                console.error(error);
                throw error;
            }
        },
    });
};

export const useCreditNumberByIdQuery = () => {
    return useMutation({
        mutationFn: async (postEventCreditNumber: { eventId: number | string; creditNumber: string | undefined }) => {
            try {
                const { data, status } = await api.patch(`/events/${postEventCreditNumber.eventId}/credit-number`, postEventCreditNumber);
                return {
                    data,
                    status,
                };
            } catch (error: any) {
                return {
                    data: error?.response?.data,
                    status: error?.response?.status,
                };
            }
        },
    });
};

export const useUpdateEventNameByIdQuery = () => {
    return useMutation({
        mutationFn: async (postEventName: { eventId: number | string; name: string | undefined }) => {
            try {
                const { data, status } = await api.patch(`/events/${postEventName.eventId}/name`, postEventName);
                return {
                    data,
                    status,
                };
            } catch (error: any) {
                return {
                    data: error?.response?.data,
                    status: error?.response?.status,
                };
            }
        },
    });
};

export const useCreatePerEventQuery = () => {
    return useMutation({
        mutationFn: async (preEvent: any) => {
            try {
                const { data, status } = await api.post(`/events`, preEvent);
                return {
                    data,
                    status,
                };
            } catch (error: any) {
                return {
                    data: error?.response?.data,
                    status: error?.response?.status,
                };
            }
        },
    });
};

export const useUpdatePerEventQuery = () => {
    return useMutation({
        mutationFn: async ({ eventId, preEvent }: { eventId: number; preEvent: any }) => {
            preEvent.id = eventId;
            try {
                const { data, status } = await api.patch(`/events/${eventId}`, preEvent);
                return {
                    data,
                    status,
                };
            } catch (error: any) {
                return {
                    data: error?.response?.data,
                    status: error?.response?.status,
                };
            }
        },
    });
};

export const useDeleteEventQuery = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (eventId: number) => {
            try {
                const { data, status } = await api.delete(`/events/${eventId}`);
                return {
                    data,
                    status,
                };
            } catch (error: any) {
                return {
                    data: error?.response?.data,
                    status: error?.response?.status,
                };
            }
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: ['events'] });
        },
    });
};

export const useUpdateEventQuery = () => {
    return useMutation({
        mutationFn: async ({ eventId, eventData }: { eventId: number; eventData: any }) => {
            try {
                const { data, status } = await api.patch(`/events/${eventId}`, eventData);
                return {
                    data,
                    status,
                };
            } catch (error: any) {
                return {
                    data: error?.response?.data,
                    status: error?.response?.status,
                };
            }
        },
    });
};
