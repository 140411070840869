import { Document, Page, Text, View, Image } from '@react-pdf/renderer';
import StyleSheet from './StyleSheet';
import { TemplateType } from '.';

const Template = ({ imageBackground, dataTemplate }: { dataTemplate: TemplateType; imageBackground: string }) => {
    return (
        <Document>
            <Page size="A4" style={StyleSheet.page}>
                <Image src={imageBackground} style={StyleSheet.pageBackground} />

                <View style={StyleSheet.textTitleContainer}>
                    <View style={StyleSheet.BoxContainer}>
                        <Text style={StyleSheet.textContent}>{dataTemplate.title}</Text>
                        <Text style={StyleSheet.textContent}>{dataTemplate.subTile}</Text>
                    </View>
                </View>
                <View style={StyleSheet.textDescContainer}>
                    <Text style={StyleSheet.textDescOne}>{`ทำการชดเชยคาร์บอนเครติดปริมาณ ${dataTemplate.totalCarbon} ตันคาร์บอนไดออกไซต์เที่ยบเท่า `}</Text>
                    <Text style={StyleSheet.textDescTwo}>{`โครงการคาร์บอนเครติด : ${dataTemplate.projectName}`}</Text>
                    <Text style={StyleSheet.textDescThree}>{`เลขที่เครดิต : ${dataTemplate.creditNumber}`}</Text>
                </View>

                <View style={StyleSheet.positionCredit}>
                    <Text style={StyleSheet.textCredit}>{dataTemplate?.credit_date}</Text>
                </View>
            </Page>
        </Document>
    );
};

export default Template;
