import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Mice from './mice';
import Form from './form';
import DownloadLogo from './downloadLogo';
import Upload from './upload';
import Payment from './payment';
import Certificate from './certificate';
import Attachment from './attachment';
import ProjectOffset from './projectOffset';
import './register.css';
import { useGlobalState } from '@src/context/GlobalStateContext';
import dayjs from 'dayjs';
import GasEmissions from './GasEmissions';
import GasSummary from './GasSummary';

export default function Register() {
    const { id, event } = useParams();
    const { formMice } = useGlobalState();
    const navigate = useNavigate();
    const [eventId, setEventId] = useState<number>();
    const [registerPagingNo, setRegisterPagingNo] = useState<number>(1);
    const [registerStatus, setRegisterStatus] = useState<string>('');
    let tmpCreateDataStr = sessionStorage.getItem('tmpCreateData');
    let tmpCreateData = tmpCreateDataStr ? JSON.parse(tmpCreateDataStr!) : {};
    let pageLanding = tmpCreateData?.paging;
    let projectStatus = tmpCreateData?.status;
    let listPageHasBackground = [0, 4, 5, 6, 7, 8, 9];

    useEffect(() => {
        if ((pageLanding < parseInt(id!) || projectStatus !== event) && pageLanding && projectStatus) {
            setRegisterStatus(projectStatus);
            setRegisterPagingNo(pageLanding);
        } else {
            setRegisterStatus(event!);
            setRegisterPagingNo(parseInt(id!));
        }
    }, [id, event, setRegisterPagingNo, pageLanding, projectStatus, navigate]);
    const { eventMember, eventPeriod, eventArea, eventMemberOnline, diffDay } = useMemo(() => {
        const startDate = dayjs(formMice?.startDate);
        const endDate = dayjs(formMice?.endDate);
        const diffDay = endDate.diff(startDate, 'day') + 1;

        return {
            eventMember: formMice?.eventMember,
            eventPeriod: formMice?.eventPeriod,
            eventArea: formMice?.eventArea,
            eventMemberOnline: formMice?.eventMemberOnline,
            diffDay,
        };
    }, [formMice]);

    useEffect(() => {
        if ((event === 'pre' || event === 'pre-update' || event === 'post') && formMice === undefined) {
            if (registerPagingNo === 2 || registerPagingNo === 3) {
                if (event !== 'post') {
                    navigate(`/register/${event}/1`);
                } else {
                    navigate(`/`);
                }
            }
        }
    }, [formMice, event, navigate, registerPagingNo]);

    useEffect(() => {
        if (formMice && formMice?.id) {
            setEventId(formMice?.id as number);
        } else if (sessionStorage.getItem('tmpEventId')) {
            setEventId(parseInt(sessionStorage.getItem('tmpEventId')!));
        }
    }, [formMice, setEventId]);

    return (
        <div className={listPageHasBackground.includes(registerPagingNo) ? 'is-background' : 'is-none-background'}>
            <div className="container">
                {registerPagingNo === 1 && (registerStatus === 'pre' || registerStatus === 'pre-update') ? <Mice modeEvent={event as string} /> : ''}
                {registerPagingNo === 2 && (registerStatus === 'pre' || registerStatus === 'pre-update' || registerStatus === 'post') ? <Form /> : ''}
                {registerPagingNo === 3 && (registerStatus === 'pre' || registerStatus === 'pre-update' || registerStatus === 'post') ? (
                    <GasEmissions
                        eventMember={eventMember ?? 0}
                        eventPeriod={eventPeriod ?? 0}
                        eventArea={eventArea ?? 0}
                        eventMemberOnline={parseInt(eventMemberOnline as string) ?? 0}
                        diffDay={diffDay}
                    />
                ) : (
                    ''
                )}
                {registerPagingNo === 4 && (registerStatus === 'pre' || registerStatus === 'pre-update' || registerStatus === 'post') ? (
                    <GasSummary modeEvent={event as string} TotalMembers={(eventMember ?? 0) + parseInt(eventMemberOnline as string)} />
                ) : (
                    ''
                )}
                {registerPagingNo === 5 && (registerStatus === 'pre' || registerStatus === 'pre-update') ? <DownloadLogo /> : ''}

                {registerPagingNo === 6 && registerStatus === 'post' ? <Upload eventId={eventId as number} /> : ''}
                {registerPagingNo === 7 && registerStatus === 'post' ? <Attachment eventId={eventId as number} /> : ''}
                {registerPagingNo === 8 && registerStatus === 'post' ? <ProjectOffset eventId={eventId as number} /> : ''}
                {registerPagingNo === 9 && registerStatus === 'post' ? <Payment eventId={eventId as number} /> : ''}
                {registerPagingNo === 10 && registerStatus === 'post' ? <Certificate /> : ''}
            </div>
        </div>
    );
}
