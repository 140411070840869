import { Button } from '@mui/material';
import { useEventsByIdQuery } from '@src/services/queries/useEventsQuery';
import { formatNumber } from '@src/utils/format-number';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { ListGasEmissions } from '../Register/GasSummary';
import { useCreateCertificate } from '@src/services/queries/useCertificateQuery';

const ViewSummary = () => {
    const navigate = useNavigate();
    const { mutateAsync } = useCreateCertificate();
    const { t } = useTranslation();
    const { id } = useParams();
    const bgImage = `${process.env.PUBLIC_URL}/assets/images/pexels-kelly.jpg`;
    const { data } = useEventsByIdQuery(parseInt(id as string));
    const onBackHome = () => {
        navigate('/');
    };

    const onClickDownloadCertificate = async (lang: string) => {
        const response = await mutateAsync({ eventId: parseInt(id as string), lang: lang });

        if (response && response.url) {
            const fileUrl = response.url;
            const res = await fetch(fileUrl);
            if (!res.ok) {
                throw new Error('Failed to fetch the file.');
            }
            const blob = await res.blob();
            const link = document.createElement('a');
            const objectUrl = URL.createObjectURL(blob);

            link.href = objectUrl;
            link.setAttribute('download', `certificate-${lang}.png`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(objectUrl); // Release memory
        } else {
            console.error('No file URL provided.');
        }
    };
    return (
        <div
            className={`flex h-screen justify-center items-center bg-no-repeat bg-center bg-cover `}
            style={{
                backgroundImage: `url('${bgImage}')`,
            }}
        >
            <div className="container">
                <div className="mx-auto max-w-6xl w-full text-center bg-white  p-8 rounded-lg">
                    <div className="py-5 pl-4">
                        <h1 className="content-header content-left">{t('SUMMARY_PAGE.HEADER')}</h1>
                    </div>
                    <div className="grid grid-cols-3  pl-4">
                        <div className=" col-span-2">
                            <div className="grid grid-cols-2 mb-0">
                                <div className="">
                                    <h1 className="content-sub-header content-left font-semibold">{t('SUMMARY_PAGE.SOURCE_CO2E')}</h1>
                                </div>
                                <div className="content-right">
                                    <h1 className="content-sub-header font-semibold">{t('SUMMARY_PAGE.SOURCE_CO2E_AMT')}</h1>
                                </div>
                            </div>

                            <div className="px-2">
                                {data?.emission?.emissions_sources.map((gas: any, index: number) => (
                                    <ListGasEmissions key={index} name={gas.name} percentage={gas.percentage} totalCarbonFootprint={data?.emission?.summary} />
                                ))}
                            </div>
                        </div>
                        <div className=" col-span-1">
                            <div className="border-[5px] border-[#C8F026] rounded-[12px] p-2 py-4 mb-4">
                                <h1 className="content-header-s !text-2xl">{t('SUMMARY_PAGE.TOTAL_CO2E')}</h1>
                                <h1 className="content-header font-bold !text-4xl" style={{ color: '#26B3F0' }}>
                                    {formatNumber(data?.emission?.summary, 2)}
                                    {/* {Math.ceil(totalCarbonFootprint)} */}
                                    {t('SUMMARY_PAGE.UNIT')}
                                </h1>
                                <h1 className="content-header-s">{t('SUMMARY_PAGE.AVG_PER_PERSON')}</h1>
                                <h1
                                    className="content-header font-bold
                                    drop-shadow-2xl
                                    "
                                    style={{ color: '#26B3F0' }}
                                >
                                    {formatNumber(data?.emission?.summary_person, 2)}
                                    {/* {Math.ceil(totalCarbonFootprintPercentage)} */}
                                    {t('SUMMARY_PAGE.UNIT')}
                                </h1>
                            </div>
                            {/* eslint-disable jsx-a11y/anchor-is-valid  */}

                            <a
                                className="button is-large is-fullwidth App"
                                style={{
                                    backgroundColor: '#54B154',
                                    fontSize: '16px',
                                    color: 'white',
                                    height: '45px',
                                    marginBottom: '10px',
                                }}
                                onClick={() => onClickDownloadCertificate('th')}
                            >
                                {t('DASHBOARD_PAGE.SUMMARY_DIALOG.BUTTON1')}
                            </a>
                            <a
                                className="button is-large is-fullwidth App"
                                style={{
                                    backgroundColor: '#54B154',
                                    fontSize: '16px',
                                    color: 'white',
                                    height: '45px',
                                    marginBottom: '10px',
                                }}
                                onClick={() => onClickDownloadCertificate('en')}
                            >
                                {t('DASHBOARD_PAGE.SUMMARY_DIALOG.BUTTON2')}
                            </a>

                            <Button
                                fullWidth
                                variant="contained"
                                onClick={onBackHome}
                                className="rounded-lg 
                        !text-black
                        !bg-[#C8F026]"
                            >
                                <span className={'!font-kanit !text-lg py-1'}>{t('DOWNLOAD_LOGO_PAGE.BUTTONS.HOME')}</span>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ViewSummary;
