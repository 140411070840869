import { PDFViewer, Font } from '@react-pdf/renderer';
import React, { useState } from 'react';
import Template from './template';
import EditToolsBar from './edit-tools-bar';
import { formatDateThai, formatDateEn } from '@src/utils/format-date-th';

export type TemplateType = {
    credit_date: string;
    title: string;
    subTile: string;
    totalCarbon: number;
    creditNumber?: string | undefined;
    projectName?: string | undefined;
};

export type TemplateDescType = {
    totalCarbon: number;
    projectName: string;
    numberCredit: string;
};

export type postEventType = {
    name?: string | undefined;
    eventOwner?: string | undefined;
    totalCarbon: number;
    creditNumber?: string | undefined;
    startDate?: string | undefined;
    projectName?: string | undefined;
};

export const fileTh = `${process.env.PUBLIC_URL}/assets/certificate/certificate-th.png`;
export const fileEn = `${process.env.PUBLIC_URL}/assets/certificate/certificate-en.png`;
const Certificate = ({ postEvent, eventId }: { eventId: number | string; postEvent: postEventType }) => {
    const date = postEvent.startDate ?? '';
    const [rawTemplate, setRawTemplate] = useState({
        credit_date: 'ZC-' + formatDateEn(postEvent.startDate ?? ''),
        title: `${postEvent.name}`,
        subTile: ` ${formatDateThai(date)} โดย ${postEvent.eventOwner}`,
        totalCarbon: postEvent.totalCarbon,
        creditNumber: `${postEvent.creditNumber}`,
        projectName: `${postEvent.projectName}`,
    });

    const [fileTemplate, setFileTemplate] = useState(fileTh);

    Font.register({
        family: 'THSan',
        src: `${process.env.PUBLIC_URL}/assets/fonts/TH-Kanit/Kanit-Light.ttf`,
    });

    return (
        <div className="w-full relative max-w-screen-lg">
            <PDFViewer showToolbar={false} height="920" width={'100%'} className="max-w-screen-lg">
                <Template dataTemplate={rawTemplate} imageBackground={fileTemplate} />
            </PDFViewer>
            <EditToolsBar
                eventId={eventId}
                dataTemplate={rawTemplate}
                setDataTemplate={setRawTemplate}
                fileTemplate={fileTemplate}
                setFileTemplate={setFileTemplate}
            />
        </div>
    );
};

export default Certificate;
