import { Button } from '@mui/material';
import Iconify from '@src/components/layout/admin/components/iconify';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const PaymentSuccess = () => {
    const { i18n } = useTranslation();
    const search = window.location.search;
    const params = new URLSearchParams(search);

    const bgImage = `${process.env.PUBLIC_URL}/assets/images/pexels-kelly.jpg`;
    const navigate = useNavigate();

    const onBackHome = () => {
        navigate('/');
    };
    return (
        <div
            className={`flex h-screen justify-center items-center bg-no-repeat bg-center bg-cover `}
            style={{
                backgroundImage: `url('${bgImage}')`,
            }}
        >
            <div className="mx-auto max-w-lg w-full text-center bg-white  p-8 rounded-lg">
                <div className="inline-flex h-16 w-16 items-center justify-center rounded-full bg-green-500">
                    <Iconify icon="simple-line-icons:check" width={30} sx={{ color: '#ffff' }} />
                </div>
                <h1 className="mt-4 text-3xl font-bold tracking-tight text-foreground sm:text-4xl">
                    {i18n.language === 'en' ? 'Payment Successful' : i18n.language === 'th' ? 'ชำระเงินสำเร็จ' : i18n.language === 'cn' ? '付款成功' : ''}
                </h1>

                <p className="mt-1 text-muted-foreground">
                    {i18n.language === 'en'
                        ? 'Your transaction was completed successfully.'
                        : i18n.language === 'th'
                          ? 'การทำธุรกรรมของคุณเสร็จสมบูรณ์แล้ว'
                          : i18n.language === 'cn'
                            ? '您的交易已成功完成。'
                            : ''}
                </p>

                <div className="mt-4 w-full  rounded-lg border bg-card p-5 shadow-sm flex flex-col">
                    <div className="grid grid-cols-1 gap-4">
                        <div>
                            <h2 className="text-lg font-medium">
                                {i18n.language === 'en'
                                    ? 'Payment Details'
                                    : i18n.language === 'th'
                                      ? 'รายละเอียดการชำระเงิน'
                                      : i18n.language === 'cn'
                                        ? '付款细节'
                                        : ''}
                            </h2>
                            <div className="mt-2 space-y-1  text-muted-foreground">
                                <div className="flex justify-between px-4">
                                    <span>
                                        {i18n.language === 'en'
                                            ? ' Transaction ID'
                                            : i18n.language === 'th'
                                              ? 'รหัสธุรกรรม'
                                              : i18n.language === 'cn'
                                                ? '交易编号'
                                                : ''}
                                    </span>
                                    <span>{params.get('t') ?? ''}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Button
                    variant="contained"
                    onClick={onBackHome}
                    className="mt-6 rounded-lg 
                        !text-black
                        !bg-[#C8F026]"
                >
                    <span className={'!font-kanit !text-lg py-1'}>กลับหน้าหลัก</span>
                </Button>
            </div>
        </div>
    );
};

export default PaymentSuccess;
